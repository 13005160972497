import Close from "./Icons/Close";
import Info from "./Icons/Info";

declare const window: Window &
  typeof globalThis & {
    info_modal: HTMLDialogElement;
  };

const Information = () => {
  return (
    <div>
      <button
        className="hover:bg-base-400 btn btn-square bg-base-300 shadow-xl"
        onClick={() => window.info_modal.showModal()}
      >
        <Info />
      </button>
      <dialog id="info_modal" className="modal">
        <form method="dialog" className="modal-box px-10">
          <h1 className="mb-2 text-xl font-bold text-center">
            EZ Password Generator Info
          </h1>
          <ul className="text-md space-y-2 text-justify list-disc list-outside">
            <li>Creates a password with 8 characters in length</li>
            <li>
              Exactly one capital letter, one number and one special character
              with the rest being lower case letters
            </li>
            <li>
              Capital letter, number and special character are always in a
              single block at either the end or the beginning of the password
            </li>
            <li>
              This creates a reasonable secure password that is still not too
              cumbersome to enter with TV remotes, game pads and the likes
            </li>
            <li>
              Only use generated passwords from this tool for accounts that dont
              have high requirements for security
            </li>
          </ul>
          <div className="modal-action flex items-center justify-center">
            <button className="btn btn-square hover:bg-base-300">
              <Close />
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
};

export default Information;
