import React from "react";

const Header = () => {
  return (
    <div className="mb-5 justify-center">
      <h1 className="text-center text-4xl font-bold text-white">
        EZ Password Generator
      </h1>
    </div>
  );
};

export default Header;
