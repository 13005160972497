const getRandomChar = (chars: string) => {
  const randomValue = crypto.getRandomValues(new Uint32Array(1))[0]!;
  return chars[randomValue % chars.length] ?? "";
};

// Replacement for Math.random()
const cryptoRandom = () => {
  const typedArray = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0]!;
  const randomFloat = randomValue / Math.pow(2, 8);
  return randomFloat;
};

const shuffleArray = (array: string[]): string[] => {
  return array.sort(() => cryptoRandom() - 0.5);
};

export const generatePassword = () => {
  const lowerChars = "abcdefghijklmnopqrstuvwxyz";
  const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()_+";

  const lowerPart = Array.from({ length: 5 }, () =>
    getRandomChar(lowerChars),
  ).join("");

  const upperPart = getRandomChar(upperChars);
  const numberPart = getRandomChar(numbers);
  const specialPart = getRandomChar(specialChars);

  const randomParts = [upperPart, numberPart, specialPart];
  const randomPartsShuffled = shuffleArray(randomParts);

  const blockAtStart = cryptoRandom() > 0.5;
  const randomBlock = randomPartsShuffled.join("");

  const newPassword = blockAtStart
    ? `${randomBlock}${lowerPart}`
    : `${lowerPart}${randomBlock}`;

  return newPassword;
};
