import { useCallback, useEffect, useState } from "react";
import Header from "./components/Header";
import Information from "./components/Information";
import Password from "./components/Password";
import SuccessAlert from "./components/SuccessAlert";
import { generatePassword } from "./utils/pw";

function App() {
  const [password, setPassword] = useState("");
  const [showClipboardSuccess, setShowClipboardSuccess] = useState(false);

  const copyToClipboard = async (password: string) => {
    if (!navigator.clipboard) {
      throw new Error("Browser doesn't have support for native clipboard.");
    }
    await navigator.clipboard.writeText(password);
    setShowClipboardSuccess(true);
    setTimeout(() => {
      setShowClipboardSuccess(false);
    }, 2000);
  };

  const getNewPassword = useCallback(async (writeToClipboard = false) => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    if (writeToClipboard) {
      await copyToClipboard(newPassword);
    }
  }, []);

  useEffect(() => {
    const initialPassword = async () => {
      await getNewPassword(false);
    };
    initialPassword().catch(console.error);
  }, [getNewPassword]);

  return (
    <main className="bg-base-200 flex flex-col items-center w-full min-h-screen">
      <div className="lg:w-1/3 flex flex-col items-center w-full h-full p-6">
        <Header />
        <div className="flex flex-col items-center justify-center flex-grow w-full py-4">
          <Password
            copyToClipboard={copyToClipboard}
            password={password}
            generatePassword={getNewPassword}
          />
          <div className="flex justify-center p-4">
            <SuccessAlert showClipboardSuccess={showClipboardSuccess} />
          </div>
        </div>
        <div className="container flex justify-center w-full mb-12">
          <Information />
        </div>
      </div>
    </main>
  );
}

export default App;
