import ClipboardCopy from "./Icons/ClipboardCopy";

type PasswordProps = {
  password: string;
  generatePassword: (writeToClipboard: boolean) => Promise<void>;
  copyToClipboard: (password: string) => Promise<void>;
};

const Password = ({
  password,
  copyToClipboard,
  generatePassword,
}: PasswordProps) => {
  return (
    <div className="card bg-base-100 text-neutral-content flex w-full shadow-xl">
      <div className="card-body items-center text-center">
        <button
          onClick={() => {
            void (async () => {
              await copyToClipboard(password);
            })();
          }}
          className="btn btn-lg flex items-center justify-between w-full mb-4 normal-case"
        >
          <ClipboardCopy />
          <h2 className="card-title text-white">{password}</h2>
          <ClipboardCopy />
        </button>
        <button
          onClick={() => {
            void (async () => {
              await generatePassword(true);
            })();
          }}
          className="btn btn-success btn-lg w-full"
        >
          Generate
        </button>
      </div>
    </div>
  );
};

export default Password;
