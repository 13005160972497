import { motion } from "framer-motion";
import React from "react";
import CheckMark from "./Icons/CheckMark";

const variants = {
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
  hide: {
    y: -20,
    opacity: 0,
  },
};

type SuccessAlertProps = {
  showClipboardSuccess: boolean;
};

const SuccessAlert = ({ showClipboardSuccess }: SuccessAlertProps) => {
  return (
    <>
      <motion.div
        key="animation-on-state"
        variants={variants}
        initial={"hide"}
        animate={showClipboardSuccess ? "show" : "hide"}
      >
        <div className="alert alert-success flex">
          <CheckMark />
          <span>Password copied to clipboard</span>
          <CheckMark />
        </div>
      </motion.div>
    </>
  );
};

export default SuccessAlert;
